// Here you can add other styles
.react-datepicker-wrapper{
    width: calc(100% - 42px);
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}
select::-ms-expand {
  display: none;
}
.c-dark-theme a{
  color: white !important;
}
.modal-content {
  top: 80px;
}
