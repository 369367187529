.thank-you-container {
  background: url('../../../images/thankyou.jpg');
  background-size: cover;
  width: 100%;
  height: 100vh;
  padding: 10%;
}

.thank-you-container h1 {
  font-size: 8vh;
  color: #8337c9;
  font-weight: 700;
  text-shadow: 2px 0px 3px #20b8a7;

}


.thank-you-container .choice-img {
  width: 12%;
  margin-left: 8%;
}

.thank-you-container p {
  width: 41%;
    font-size: 25px;
    color: #535252;
}

.thank-you-container a {
  margin: 15% 2% 0 0;
}